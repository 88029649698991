import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductSlider from "../components/ProductSlider";
import { AiFillCloseCircle } from "react-icons/ai";
import RichText from "../components/RichText";
import useCart from "../state";
import { useNavigate } from "react-router-dom";


function ProductPage2() {
    const product_id = useParams().product_id;
    const [images, setImages] = useState([]);
    const [product, setProduct] = useState();
    const items = useCart((state) => state.items);
    const addItem = useCart((state) => state.addToCart);
    const setOpen = useCart((state) => state.setOpen);
    const navigate = useNavigate();


    const isProductInCart = items.some(
        (item) => item.id === product?.id
      );

    useEffect(() => {
        const fetchHero = async () => {
            const response = await fetch(
                `https://surroundback.vercel.app/product?id=${product_id}`
            );
            const data = await response.json();
            setProduct(data[0]);
            setImages(data[0].images);
        };
        fetchHero();
    }, [product_id]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <>
            <div className="pt-20 min-h-[900px]">
                <div className="flex flex-col lg:flex-row w-100  gap-5">
                    <div className="flex justify-center items-start">
                        <ProductSlider product={product} images={images} />
                    </div>
                    {product && (
                        <div className="lg:py-20 px-10">
                            <div className="flex flex-row justify-between">
                                {product.is_pre_order ? <p className="font-akzidenz-regular text-WaterLeaf text-lg">
                                    Pre-Order
                                </p> : product.in_stock ? (
                                    <p className="font-akzidenz-regular text-WaterLeaf text-lg">
                                        In Stock
                                    </p>
                                ) : (
                                    <p className="font-akzidenz-regular text-red-500 text-lg">
                                        Out of Stock
                                    </p>
                                )}

                                <AiFillCloseCircle
                                    size={25}
                                    onClick={() => navigate("/")}
                                    className="cursor-pointer"
                                />
                            </div>
                            <h1 className="font-akzidenz-bold text-4xl">
                                {product.brand && product.brand.name}{" "}
                                {product.model}
                            </h1>
                            <div className="h-1 bg-black mb-6"></div>
                            <div className="h-[300px] overflow-auto">
                                {product.description != null && (
                                    <RichText text={product.description} />
                                )}
                            </div>
                            <div className="h-1 bg-black mt-10"></div>
                            <p className="font-akzidenz-medium text-3xl mt-4">
                                ${product.price && product.price.toLocaleString()}
                            </p>
                            <button
                                className="bg-Shark text-WaterLeaf mt-10 rounded-xl w-full h-10 my-4 hover:-translate-y-1 hover:shadow-md hover:shadow-gray-700 transition duration-500 ease-in-out"
                                disabled={isProductInCart}
                                onClick={() => {
                                    addItem({
                                        Model: product.model,
                                        Category:
                                            product.category.name,
                                        Price: product.price,
                                        Image: product.imagen_url,
                                        id: product.id,
                                        Stripe_id: product.stripe_id,
                                        quantity: 1,
                                    });
                                    setOpen(true);
                                }}
                            >
                                {isProductInCart ? "In Cart" : "Add to Cart"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ProductPage2