import React, { useState } from 'react'
import Categories from '../components/cms/Categories'
import Brands from '../components/cms/Brands'
import Products from '../components/cms/Products'
import { useStore } from '../store'
import FeaturedSection from '../components/cms/FeaturedSection'
import HeroSection from '../components/cms/HeroSection'

function Admin() {

    const [active, setActive] = useState('Products')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const user = useStore(state => state.user)
    const setUser = useStore(state => state.setUser)

    async function auth() {
        const response = await fetch('https://surroundback.vercel.app/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: { email, password }
        })
        const data = await response.json()
        setUser(data)
    }

    return (
        <>
            {!user && <div className="w-full h-screen flex flex-col bg-Shark justify-center items-center">
                <div className="w-96 h-96 bg-white rounded-2xl p-10 flex flex-col justify-center items-center">
                    <h1 className="text-3xl text-center text-Shark">Login</h1>
                    <input type="text" name="model" placeholder='email' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center text-white' onChange={(event) => setEmail(event.target.value)} />
                    <input type="password" name="model" placeholder='password' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center text-white' onChange={(event) => setPassword(event.target.value)} />
                    <button onClick={auth} className='w-full h-10 mt-4 bg-WaterLeaf text-Shark py-6  rounded-full text-center flex justify-center items-center'>Login</button>
                </div>
            </div>}
            {user && <div className="w-full flex flex-col">
                <div className="mt-20 bg-white text-Shark h-10 w-full flex flex-row gap-20 justify-center">
                    <button class="px-6" style={{ backgroundColor: active === "Products" ? 'black' : '', color: active === "Products" ? 'white' : '' }} onClick={() => setActive("Products")}>Products</button>
                    <button class="px-6" style={{ backgroundColor: active === "Categories" ? 'black' : '', color: active === "Categories" ? 'white' : '' }} onClick={() => setActive("Categories")}>Categories</button>
                    <button class="px-6" style={{ backgroundColor: active === "Brands" ? 'black' : '', color: active === "Brands" ? 'white' : '' }} onClick={() => setActive("Brands")}>Brands</button>
                    <button class="px-6" style={{ backgroundColor: active === "Hero" ? 'black' : '', color: active === "Hero" ? 'white' : '' }} onClick={() => setActive("Hero")}>Hero Section</button>
                    <button class="px-6" style={{ backgroundColor: active === "Featured" ? 'black' : '', color: active === "Featured" ? 'white' : '' }} onClick={() => setActive("Featured")}>Featured Section</button>
                </div>
                <div>
                    {active === 'Categories' && <Categories />}
                    {active === 'Brands' && <Brands />}
                    {active === 'Products' && <Products />}
                    {active === 'Featured' && <FeaturedSection />}
                    {active === 'Hero' && <HeroSection />}
                </div>
            </div>}

        </>
    )
}

export default Admin