import React, { useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

function ProductSlider({ product, images }) {
  const [active, setActive] = useState(0);
  return (
    <div className="p-10 md:p-20">
      <div className="flex flex-col justify-center items-center">
        {product && (
          <InnerImageZoom
            className="w-[300px] h-[300px] md:w-[500px] md:h-[500px]"
            src={
              images[active]
            }
            zoomSrc={product.imagen_url}
            fullscreenOnMobile={true}
          />
        )}
        <div className="flex flex-row flex-wrap w-[300px] md:w-[500px] justify-center gap-3">
          {images &&
            images.map((image, index) => (
              <div
                key={index}
                className="w-[90px] h-[90px] bg-contain bg-no-repeat cursor-pointer"
                onClick={() => {
                  setActive(index);
                }}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ProductSlider;
