import React, { useState, useEffect } from 'react'
import CloudinaryUploaderMultiple from './CloudinaryUploaderMultiple'


function EditProduct({ setEditProductOpen, id }) {

    const [productToEdit, setProductToEdit] = useState([])

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [model, setModel] = useState('');
    const [price, setPrice] = useState('')
    const [stripeId, setStripeId] = useState('')
    const [isFeatured, setIsFeatured] = useState(false)
    const [isNew, setIsNew] = useState(true)
    const [isPreOrder, setIsPreOrder] = useState(false)
    const [inStock, setInStock] = useState(true)
    const [images, setImages] = useState(null)
    const [description, setDescription] = useState("")

    async function updateProduct() {
        const data = {
            brand: brand,
            category: category,
            model: model,
            price: price,
            stripe_id: stripeId,
            is_featured: isFeatured,
            is_new: isNew,
            is_pre_order: isPreOrder,
            in_stock: inStock,
            images: images,
            imagen_url: images[0],
            description: description
        }

        fetch(`https://surroundback.vercel.app/update-product?id=${productToEdit.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => window.location.reload())
            .catch(error => console.error('Error:', error));


    }

    useEffect(() => {
        fetch('https://surroundback.vercel.app/product?id=' + id)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setProductToEdit(data[0]);
                setModel(data[0].model);
                setPrice(data[0].price);
                setStripeId(data[0].stripe_id);
                setIsFeatured(data[0].is_featured);
                setIsNew(data[0].is_new);
                setIsPreOrder(data[0].is_pre_order);
                setInStock(data[0].in_stock);
                setCategory(data[0].category.id)
                setBrand(data[0].brand.id)
                setDescription(data[0].description)
                setImages(data[0].images)
            })
    }, [id])


    useEffect(() => {
        fetch('https://surroundback.vercel.app/brands')
            .then(response => response.json())
            .then(data => setBrands(data))
    }
        , [])

    useEffect(() => {
        fetch('https://surroundback.vercel.app/categories')
            .then(response => response.json())
            .then(data => setCategories(data))
    }
        , [])

    const handleDelete = (index) => {
        setImages(images.filter((_, i) => i !== index));
    }


    return (
        <div className='w-full h-screen fixed bg-black/80 top-0 flex justify-center items-center'>

            {productToEdit && <div className='relative w-96 h-[600px] overflow-auto bg-white rounded-2xl flex flex-col items-center p-10'>
                <button onClick={() => setEditProductOpen(false)} className='absolute top-5 right-5 z-50 w-8 h-8 bg-Shark text-white rounded-full flex justify-center items-center'>x</button>
                <h1 className='text-black text-xl font-bold mt-6'>Edit Product</h1>
                <select
                    className='w-full h-10 mt-4 bg-Shark rounded-full text-center'
                    value={productToEdit?.brand?.id}
                    onChange={(event) => setBrand(event.target.value)}
                >
                    <option value=''>Select Brand</option>
                    {brands.map(brand => (
                        brand.name === productToEdit?.brand?.name ?
                            <option key={brand.id} selected value={brand.id}>{brand.name}</option> :
                            <option key={brand.id} value={brand.id}>{brand.name}</option>

                    ))}
                </select>
                <select className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setCategory(event.target.value)}>
                    <option value=''>Select Category</option>
                    {categories.map(category => (
                        category.name === productToEdit?.category?.name
                            ? <option key={category.id} value={category.id} selected>{category.name}</option>
                            : <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
                <input type="text" defaultValue={model} name="model" placeholder='Model' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setModel(event.target.value)} />
                <input type="number" defaultValue={price} name="price" placeholder='Price' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setPrice(event.target.value)} />
                <input type="text" name="stripe_id" defaultValue={stripeId} placeholder='Stripe_id' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setStripeId(event.target.value)} />
                <div className='w-full h-60 my-6'>
                    <textarea value={description} id="message" rows="4" onChange={(e) => setDescription(e.target.value)} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description..."></textarea>
                </div>
                {isFeatured ? <button onClick={() => setIsFeatured(false)} className='w-full py-2 h-10 mt-4 bg-WaterLeaf rounded-full text-center text-black'>Featured</button> : <button onClick={() => setIsFeatured(true)} className='w-full py-2 mt-4 bg-Shark rounded-full text-center'>Not Featured</button>}
                {isNew ? <button onClick={() => setIsNew(false)} className='w-full py-2 h-10 mt-4 bg-WaterLeaf rounded-full text-center text-black'>New</button> : <button onClick={() => setIsNew(true)} className='w-full py-2 h-10 mt-4 bg-Shark rounded-full text-center'>Not New</button>}
                {isPreOrder ? <button onClick={() => setIsPreOrder(false)} className='w-full py-2 h-10 mt-4 bg-WaterLeaf rounded-full text-center text-black'>Pre Order</button> : <button onClick={() => setIsPreOrder(true)} className='w-full h-10 mt-4 bg-Shark py-2 rounded-full text-center'>Not Pre Order</button>}
                {inStock ? <button onClick={() => setInStock(false)} className='w-full py-2 h-10 mt-4 bg-WaterLeaf rounded-full text-center text-black'>In Stock</button> : <button onClick={() => setInStock(true)} className='w-full h-10 mt-4 bg-Shark rounded-full text-center py-2'>Out of Stock</button>}
                <div className='flex flex-col gap-4 my-4'>
                    {images?.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt="Product" />
                            <button
                                className="bg-black text-white w-full py-2"
                                onClick={() => handleDelete(index)}
                            >
                                Delete
                            </button>
                        </div>
                    ))}
                </div>

                <div className='flex w-full justify-center py-4'>
                    <CloudinaryUploaderMultiple setImagenes={setImages} />
                </div>

                <button onClick={updateProduct} className='w-full h-10 mt-4 bg-Shark text-WaterLeaf py-6  rounded-full text-center flex justify-center items-center'>Edit Product</button>

            </div>}
        </div>
    )
}


export default EditProduct