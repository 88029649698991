import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home2 from "./pages/Home2";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ProductPage2 from "./pages/ProductPage2";
import Category from "./pages/Category";
import Brands from "./pages/Brands";
import Admin from "./pages/Admin";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/products" element={<ProductPage2 />} />
        <Route path="/products/:product_id" element={<ProductPage2 />} />
        <Route path="/categories/:category" element={<Category />} />
        <Route path="/brands/:brand" element={<Brands />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}


export default App;
