import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Dropdown({ name, items }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="relative">
      <nav>
        <motion.div whileHover={{ y: -3 }} button onClick={toggleMenu} className="cursor-pointer">{name}</motion.div>
        <AnimatePresence>
          {isOpen && (
            <motion.ul
              className="absolute px-4 w-40 mt-4"
              initial={{ opacity: 0, y: -50, x: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -40 }}
            >
              {items.map((item) => (
                <motion.li
                  key={item.id}
                  className="bg-WaterLeaf my-3 rounded-full cursor-pointer py-1 text-Shark shadow-md shadow-black"
                  whileHover={{ y: -5 }}
                  onClick={() => {
                    navigate(`/${name}/${item.name}`);
                    setIsOpen(false);
                  }}
                >
                  {item.name}
                </motion.li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </nav>
    </div>
  );
}

export default Dropdown;
