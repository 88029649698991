import React, { useState, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import RichText from "./RichText";

function ThankYou({ thankyou }) {
  const navigate = useNavigate();
  const [ty, setTy] = useState(false);

  useEffect(() => {
    fetch(
      "https://surroundprostrapi-production.up.railway.app/api/thank-you-page"
    )
      .then((res) => res.json())
      .then((data) => {
        setTy(data.data);
      });
  }, []);

  return (
    <div className="flex flex-col bg-black w-full h-[calc(100vh-80px)] fixed top-20 z-40 bg-opacity-90  items-center overflow-auto">
      {ty&&<motion.div 
      animate={{x:0}}
      initial={{x:500}}
      className="bg-white p-10 md:p-20 rounded-2xl m-10 w-[300px] md:w-[500px] shadow-lg shadow-black">
        <h1 className="text-center uppercase font-akzidenz-bold text-lg mb-6">
          {ty.attributes.Title}
        </h1>
        <div>
          <RichText text={ty.attributes.Text} align={"center"} />
        </div>
        <div>
          <AiFillCloseCircle
            size={35}
            className="mt-10 cursor-pointer w-full text-center"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </motion.div>}
    </div>
  );
}

export default ThankYou;
