import React from "react";
import { useState, useEffect } from "react";
import useCart from "../state";
import WhiteLogo from "../assets/images/WhiteLogo.svg";
import TopNavBar from "./TopNavBar";
import { useNavigate } from "react-router-dom";
import { AiOutlineShopping } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import Cart from "./Cart";
import AboutUs from "./AboutUs";
import Dropdown from "./Dropdown";

function NavBar() {
  const navigate = useNavigate();
  const [topNavBar, setTopNavBar] = useState([]);
  const setOpen = useCart((state) => state.setOpen);
  const open = useCart((state) => state.open);
  const quantity = useCart((state) => state.items?.length || 0);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [auOpen, setAuOpen] = useState(false);

  useEffect(() => {
    const fetchTopNavBar = async () => {
      const response = await fetch(
        "https://surroundback.vercel.app/top-nav-bars"
      );
      const top = await response.json();
      if (top ) {
        setTopNavBar(top);
      }
    };
    fetchTopNavBar();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch(
        "https://surroundback.vercel.app/categories"
      );
      const categories = await response.json();
      setCategories(categories);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      const response = await fetch(
        "https://surroundback.vercel.app/brands"
      );
      const brands = await response.json();
      setBrands(brands);
    };
    fetchBrands();
  }, []);

  return (
    <>

      {topNavBar && topNavBar.Visible && (
        <TopNavBar
          text={topNavBar.text}
          color={topNavBar.text_color}
          bgColor={topNavBar.background_color}
        />
      )}

      <div
        className={`flex justify-between md:grid grid-cols-4 xl:grid-cols-6 fixed w-screen h-20 ${
          topNavBar.visible && "top-10"
        } h-20 transition duration-[1500ms] px-5 lg:px-20 z-50 font-akzidenz-regular text-lg bg-black text-white`}
      >
        <div className="flex flex-row items-center font-akzidenz-medium text-3xl col-span-1 xl:col-span-3 self-center">
          <img
            src={WhiteLogo}
            alt="Logo"
            className="h-14 self-center inline-block mr-2 transition duration-[1500ms] ease-in-out cursor-pointer  hover:scale-150 mt-2"
            onClick={() => {
              navigate("/");
            }}
          />
          <p
            className="ml-4 cursor-pointer hidden xl:block"
            onClick={() => navigate("/")}
          >
            Surround Pro Audio Solutions
          </p>
        </div>
        <div className="md:flex flex-row justify-around col-span-2 hidden text-center self-center">
          <Dropdown name="Categories" items={categories} />
          <Dropdown name="Brands" items={brands} />
          <div
            className="hover:-translate-y-1 cursor-pointer font-akzidenz-light text-lg"
            onClick={() => {
              setAuOpen(!auOpen);
            }}
          >
            {auOpen ? (
              <AiFillCloseCircle
                size={30}
                onClick={() => {
                  setAuOpen(false);
                }}
              />
            ) : (
              <div>About Us</div>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-around col-span-1  text-center self-center">
          <div className="flex flex-row">
            {open ? (
              <AiFillCloseCircle
                size={30}
                onClick={() => {
                  setOpen(!open);
                }}
                className="cursor-pointer mr-2"
              />
            ) : (
              <AiOutlineShopping
                size={30}
                onClick={() => {
                  setOpen(!open);
                }}
                className="cursor-pointer mr-2"
              />
            )}
            {quantity && quantity > 0 ? (
              <div
                className="flex flex-col justify-center items-center rounded-full w-6 h-6 text-center cursor-pointer bg-white text-black transition duration-[1500ms] ease-in-out"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {quantity}
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div />
        </div>
      </div>
      <Cart />
      <AboutUs auOpen={auOpen} />
    </>
  );
}

export default NavBar;
