import React, { useEffect, useState } from 'react'
import CloudinaryUploader from './CloudinaryUploader'
import Sorter from './Sorter'


function Categories() {

    const [categories, setCategories] = useState([])
    const [nuevaImagen, setNuevaImagen] = useState('')
    const [nuevaCategoria, setNuevaCategoria] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [orderOpen, setOrderOpen] = useState(false)

    async function addCategory() {
        const response = await fetch('https://surroundback.vercel.app/add-category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: nuevaCategoria,
                imagen_url: nuevaImagen
            })
        })
        await response.json()
        window.location.reload();
    }

    async function deleteCategory(id) {

        const response = await fetch('https://surroundback.vercel.app/del-category?id=' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        })
        await response.json()
        window.location.reload();
    }



    useEffect(() => {
        fetch('https://surroundback.vercel.app/categories')
            .then(response => response.json())
            .then(data => setCategories(data))
    }, [])

    useEffect(() => {
        if (categories) {
            setCategoryList(categories.map(category => category.name))
        }
    }
        , [categories])

    return (
        <div className='flex flex-col gap-6 py-10 bg-Shark text-white'>
            <h1 className='text-2xl font-semibold w-full text-center'>CATEGORIES</h1>
            <div className='w-full flex items-center justify-center'>
                <button onClick={() => setOrderOpen(true)} className='bg-black px-4 py-2 rounded-full'>Ordenar</button>
            </div>
            <div className='flex flex-row justify-center items-center gap-4'>
                {nuevaImagen ? <img src={nuevaImagen} className='w-10 h-10 rounded-full border-2' alt={nuevaCategoria} /> :
                    <CloudinaryUploader setNuevaImagen={setNuevaImagen} />}
                <input
                    className="text-black text-center rounded-full px-4 py-2"
                    type="text"
                    name="Categoria"
                    placeholder='New Category'
                    value={nuevaCategoria}
                    onChange={(event) => setNuevaCategoria(event.target.value)}
                />
                <button
                    type="button"
                    onClick={() => addCategory()}
                    className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                >
                    Agregar
                </button>
            </div>
            {categories && categories.map(category => (
                <div key={category.id} className='flex flex-row justify-center items-center gap-4'>
                    <img src={category.imagen_url} className='w-10 h-10 rounded-full border-2' alt={category.name} />
                    <h1 className='text-lg'>{category.name}</h1>
                    <button
                        type="button"
                        onClick={() => deleteCategory(category.id)}
                        className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                    >
                        Borrar
                    </button>
                </div>
            ))
            }
            {orderOpen && <Sorter list={categoryList} setOrderOpen={setOrderOpen} table="categories"/>}

        </div>


    )
}

export default Categories