import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { motion } from "framer-motion";
import ProductCard from "../components/ProductCard";
import ThankYou from "../components/ThankYou";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


function Home2() {
    const navigate = useNavigate();
    const [hero, setHero] = useState();
    const [featuredProducts, setFeaturedProducts] = useState();
    const [featured, setFeatured] = useState();
    const [categories, setCategories] = useState();
    const [brands, setBrands] = useState();
    const [news, setNews] = useState();
    const [preOrders, setPreOrders] = useState();

    const queryParams = new URLSearchParams(window.location.search);
    const thankyou = queryParams.get("thankyou");



    useEffect(() => {
        const fetchHero = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/hero"
            );
            const data = await response.json();
            setHero(data);
        };
        fetchHero();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/featured-products"
            );
            const data = await response.json();
            setFeaturedProducts(data);
        };
        fetchProducts();
    }
        , []);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/pre-orders"
            );
            const data = await response.json();
            setPreOrders(data);
        };
        fetchProducts();
    }
        , []);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/new-products"
            );
            const data = await response.json();
            setNews(data);
        };
        fetchProducts();
    }
        , []);

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/categories"
            );
            const categories = await response.json();
            setCategories(categories);
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchFeatured = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/featured"
            );
            const data = await response.json();
            setFeatured(data);
        };
        fetchFeatured();
    }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            const response = await fetch(
                "https://surroundback.vercel.app/brands"
            );
            const brands = await response.json();
            setBrands(brands);
        };
        fetchBrands();
    }, []);


    return (
        <>
            <Swiper
                spaceBetween={200}
                centeredSlides={false}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper h-[768px] w-full flex justify-center items-center bg-Shark"
            >
                {hero &&
                    hero.map((item) => (
                        <SwiperSlide key={item.id}>
                            <motion.div
                                key={item.id}
                                whileHover={{
                                    scale: 1.2,
                                    transition: { repeat: Infinity, duration: 5 },
                                }}
                                className="flex flex-row w-full h-full bg-cover bg-no-repeat bg-left"
                                style={{
                                    backgroundImage: `url(${item.imagen})`,
                                }}
                            >
                                <div
                                    className="w-full flex flex-row items-center px-[20%] bg-black bg-opacity-70 lg:bg-opacity-20 "
                                    style={{ justifyContent: `${item.align}` }}
                                >
                                    <div className="flex flex-col gap-4 w-96">
                                        <h1
                                            className={"font-akzidenz-bold text-5xl text-center"}
                                            style={{ color: item.title_color }}
                                        >
                                            {item.title}
                                        </h1>
                                        <h3
                                            className="font-akzidenz-regular text-xl text-center"
                                            style={{ color: item.subtitle_color }}
                                        >
                                            {item.subtitle}
                                        </h3>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                    ))}
            </Swiper>
            <div className="w-full h-10 bg-Shark absolute border-t-3 border-WaterLeaf"></div>
            {/*>>>>>>>>>>>>>>>>>>>>< Featured Products<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div className="flex flex-col bg-Shark w-full ">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between px-10 lg:px-40 py-20">
                        <h1 className="font-akzidenz-bold text-white text-3xl">
                            Featured Products
                        </h1>
                        <button
                            className="bg-LimedSpruce w-32 h-12 rounded-md text-WaterLeaf"
                            onClick={() => navigate("/categories/All")}
                        >
                            Shop All
                        </button>
                    </div>
                    <div className="px-32 pb-20 flex flex-row gap-10 flex-wrap justify-center">
                        {featuredProducts && featuredProducts.map((product) => (
                            <ProductCard
                                key={product.id}
                                id={product.id}
                                Model={product.model}
                                Brand={product.brand.name}
                                Image={
                                    product.imagen_url
                                }
                                Category={product.category.name}
                                Price={product.price}
                                Stripe_id={product.stripe_id}
                                InStock={product.in_stock}
                                IsPreOrder={product.is_pre_order}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/*>>>>>>>>>>>>>>>>>>>>>>CATEGORIES<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div
                id="categories"
                className="w-full bg-LimedSpruce px-10 lg:px-40 py-20 border-y-3 border-WaterLeaf"
            >
                <h1 className="font-akzidenz-bold text-white text-3xl mb-10">
                    Categories
                </h1>
                {categories && (
                    <div key={categories.id} className="flex flex-row flex-wrap justify-center items-center">
                        <div className="col-span-1"></div>
                        {categories.map((category) => (
                            <div
                                key={category.id}
                                className="flex flex-col justify-center items-center m-6"
                                onClick={() =>
                                    navigate(`/categories/${category.name}`)
                                }
                            >
                                <img
                                    className="rounded-full bg-white cursor-pointer p-4 w-40 h-40"
                                    src={category.imagen_url}
                                    alt={category.name}
                                />
                                <p className="font-akzidenz-regular text-white text-xl mt-4">
                                    {category.name}
                                </p>
                            </div>
                        ))}
                        <div className="col-span-1"></div>
                    </div>
                )}
            </div>
            {/*>>>>>>>>>>>>>>>>>>>>>>FEATURED SECTION<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <Swiper
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper h-[768px] w-full flex justify-center items-center"
            >
                {featured &&
                    featured.map((item) => (
                        <SwiperSlide>
                            <motion.div
                                key={item.id}
                                whileHover={{
                                    scale: 1.2,
                                    transition: { repeat: Infinity, duration: 5 },
                                }}
                                className="flex flex-row w-full h-full bg-cover bg-no-repeat bg-left"
                                style={{
                                    backgroundImage: `url(${item.imagen})`,
                                }}
                            >
                                <div
                                    className="w-full flex flex-row items-center px-[20%] bg-black bg-opacity-70 lg:bg-opacity-20"
                                    style={{ justifyContent: `${item.text_align}` }}
                                >
                                    <div className="flex flex-col gap-4 w-96">
                                        <h1
                                            className={"font-akzidenz-bold text-5xl text-center"}
                                            style={{ color: item.title_color }}
                                        >
                                            {item.title}
                                        </h1>
                                        <h3
                                            className="font-akzidenz-regular text-xl text-center"
                                            style={{ color: item.subtitle_color }}
                                        >
                                            {item.subtitle}
                                        </h3>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                    ))}
            </Swiper>
            <div className="w-full h-10 bg-LimedSpruce absolute border-t-3 border-WaterLeaf"></div>


            {/*>>>>>>>>>>>>>>>>>>>>>>BRANDS<<<<<<<<<<<<<<<<<<<<<<<<*/}

            <div
                id="categories"
                className="w-full bg-LimedSpruce px-10 lg:px-40 py-10"
            >
                <h1 className="font-akzidenz-bold text-white text-3xl mb-10">Brands</h1>
                {brands && (
                    <div className="flex flex-row flex-wrap justify-center items center">
                        <div className="col-span-1"></div>
                        {brands?.map((brand) => (
                            <div
                                key={brand.id}
                                className="flex flex-col justify-center items-center m-6"
                                onClick={() => navigate(`/brands/${brand.name}`)}
                            >
                                <img
                                    className="rounded-full bg-white cursor-pointer p-1 w-32 h-32"
                                    src={brand.imagen_url}
                                    alt={brand.name}
                                />
                                <p className="font-akzidenz-regular text-white text-xl mt-4">
                                    {brand.name}
                                </p>
                            </div>
                        ))}
                        <div className="col-span-1"></div>
                    </div>
                )}
            </div>
            {/*>>>>>>>>>>>>>>>>>>>>>>> New <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div className="flex flex-col bg-Shark w-full border-t-3 border-WaterLeaf">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between px-10 lg:px-40 py-20">
                        <h1 className="font-akzidenz-bold text-white text-3xl">
                            New Products
                        </h1>
                        <button
                            className="bg-LimedSpruce w-32 h-12 rounded-md text-WaterLeaf"
                            onClick={() => navigate("/categories/All")}
                        >
                            Shop All
                        </button>
                    </div>
                    <div className="px-32 pb-20 flex flex-row gap-10 flex-wrap justify-center">
                        {news &&
                            news.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    Model={product.model}
                                    Brand={product.brand.name}
                                    Image={
                                        product.imagen_url
                                    }
                                    Category={product.category.name}
                                    Price={product.price}
                                    Stripe_id={product.stripe_id}
                                    InStock={product.in_stock}
                                    IsPreOrder={product.is_pre_order}
                                />
                            ))}
                    </div>
                </div>
            </div>
            {/*>>>>>>>>>>>>>>>>>>>>>>> PRE ORDERS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div className="flex flex-col bg-Shark w-full border-t-3 border-WaterLeaf">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between px-10 lg:px-40 py-20">
                        <h1 className="font-akzidenz-bold text-white text-3xl">
                            Pre-Orders
                        </h1>
                    </div>
                    <div className="px-32 pb-20 flex flex-row gap-10 flex-wrap justify-center">
                        {preOrders &&
                            preOrders.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    Model={product.model}
                                    Brand={product.brand.name}
                                    Image={
                                        product.imagen_url
                                    }
                                    Category={product.category.name}
                                    Price={product.price}
                                    Stripe_id={product.stripe_id}
                                    InStock={product.in_stock}
                                    IsPreOrder={product.is_pre_order}
                                />
                            ))}
                    </div>
                </div>
            </div>
            {thankyou === "true" && <ThankYou thankyou={thankyou} />}
        </>
    )
}

export default Home2