import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai";
import RichText from "./RichText";

function Faqs({ setFaqsOpen }) {
  const [active, setActive] = useState(null);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetch("https://surroundback.vercel.app/faqs")
      .then((res) => res.json())
      .then((data) => {
        setFaqs(data);
      });
  }, []);

  return (
    <div className="w-full h-screen bg-black bg-opacity-90 fixed top-20 z-50 flex flex-col pt-20 items-center overflow-scroll pb-40">
      <div
        className="w-[300px] md:w-[400px] flex justify-between mb-4 cursor-pointer"
        onClick={() => setFaqsOpen(false)}
      >
        <h1 className="font-akzidenz-bold text-2xl">FAQS</h1>
        <AiFillCloseCircle size={30} />
      </div>
      {faqs.map((faq) => (
        <div key={faq.id}>
          <div
            className="h-16 w-80 md:w-96 bg-white rounded-xl text-black cursor-pointer flex justify-center items-center my-1 text-center"
            onClick={() => {
              setActive(active !== faq.id ? faq.id : null);
            }}
          >
            {faq.question}
          </div>
          {active === faq.id ? (
            <motion.div
              initial={{ y: -50 }}
              animate={{ y: 0 }}
              className="w-80 md:w-96 p-6 bg-Shark text-white rounded-xl my-4 flex justify-center items-center text-center"
            >
              <RichText text={faq.answer} />
            </motion.div>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default Faqs;
