import React, { useState } from "react";
import WhiteLogo from "../assets/images/WhiteLogo.svg";
//import { BsFacebook } from "react-icons/bs";
//import { AiFillInstagram } from "react-icons/ai";
//import { AiFillTwitterCircle } from "react-icons/ai";
import {AiFillPhone} from "react-icons/ai"
import {GrMail} from "react-icons/gr"
import Faqs from "./Faqs";
import Privacy from "./Privacy";
import Terms from "./Terms";

function Footer() {
  const [faqsOpen, setFaqsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  return (
    <div className="w-full bg-black text-white flex flex-col md:flex-row p-8 justify-center items-center border-y-2 border-white">
      {faqsOpen && <Faqs setFaqsOpen={setFaqsOpen} />}
      {privacyOpen && <Privacy setPrivacyOpen={setPrivacyOpen} />}
      {termsOpen && <Terms setTermsOpen={setTermsOpen} />}
      <div className="w-full flex flex-col justify-center items-center ">
        <img src={WhiteLogo} alt="logo" className="w-40 h-40" />
        <h1>Surround Pro Audio Solutions</h1>
      </div>
      <div className="w-full gap-6 flex flex-col justify-around items-center mt-6 md:mt-0">
        <p onClick={() => setFaqsOpen(!faqsOpen)} className="cursor-pointer">
          FAQS
        </p>
        <p
          onClick={() => setPrivacyOpen(!privacyOpen)}
          className="cursor-pointer"
        >
          Privacy Policy
        </p>
        <p onClick={() => setTermsOpen(!termsOpen)} className="cursor-pointer">
          Terms and Conditions
        </p>
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-6">
        <div className="mt-6 md:mt-0">CONTACT</div>
        <p>Surround Pro Audio Solutions</p>
        <p className="flex flex-row justify-center items-center gap-2"> <AiFillPhone/>(830) 421 3112</p>
        <p className="flex flex-row justify-center items-center gap-2"><GrMail/>sales@surroundproaudiosolutions.com</p>
      </div>
    </div>
  );
}

export default Footer;
