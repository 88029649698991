import React from "react";
import useCart from "../state";
import { useNavigate } from "react-router-dom";

function ProductCard({
  Brand,
  Model,
  Category,
  Price,
  Image,
  id,
  quantity,
  Stripe_id,
  InStock,
  IsPreOrder
}) {
  const navigate = useNavigate();
  const addItem = useCart((state) => state.addToCart);
  const setOpen = useCart((state) => state.setOpen);
  const items = useCart((state) => state.items);
  const isProductInCart = items.some((item) => item.id === id);

  console.log(items)

  return (
    <>
      <div className="flex flex-col items-center justify-center  p-4 min-w-[300px] bg-gradient-to-b from-white via-white to-gray-400 rounded-3xl shadow-2xl shadow-black">
        <img
          className="bg-white rounded-xl h-44 w-44 object-contain transform hover:scale-110 transition duration-500 ease-in-out cursor-pointer"
          src={Image}
          alt="Product"
          onClick={() => {
            navigate(`/products/${id}`);
          }}
        />
        <h2
          className="font-akzidenz-light text-base pb-2 cursor-pointer"
          onClick={() => {
            navigate(`/products/${id}`);
          }}
        >
          {Category}
        </h2>
        <h1
          className="font-akzidenz-medium text-2xl pt-2 cursor-pointer w-[250px] text-center"
          onClick={() => {
            navigate(`/products/${id}`);
          }}
        >
          {Brand} {Model}
        </h1>

        <p
          className="font-akzidenz-medium text-2xl py-2 cursor-pointer"
          onClick={() => {
            navigate(`/products/${id}`);
          }}
        >
          {Price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
        <button
          className="bg-Shark text-WaterLeaf rounded-xl w-5/6 h-10 my-4 hover:-translate-y-1 hover:shadow-md hover:shadow-gray-700 transition duration-500 ease-in-out"
          onClick={() => {
            addItem({
              Brand,
              Model,
              Category,
              Price,
              Image,
              id,
              Stripe_id,
              quantity: 1,
            });
            setOpen(true);
          }}
          disabled={isProductInCart}
        >
          {isProductInCart ? "In Cart" : IsPreOrder ? "Pre-Order" : InStock? "Add to Cart" : "Out of Stock"}
        </button>
      </div>
    </>
  );
}

export default ProductCard;
