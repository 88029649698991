import React, { useEffect, useState } from 'react'
import NewProduct from './NewProduct'
import EditProduct from './EditProduct'

function Products() {
    const [products, setProducts] = useState([])
    const [createProductOpen, setCreateProductOpen] = useState(false)
    const [editProductOpen, setEditProductOpen] = useState(false)
    const [productToEditId, setProductToEditId] = useState(1)

    async function delProduct(id) {
        fetch(`https://surroundback.vercel.app/del-product?id=${id}`, {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(data => window.location.reload())
            .catch(error => console.error('Error:', error));
    }





    useEffect(() => {
        fetch('https://surroundback.vercel.app/products')
            .then(response => response.json())
            .then(data => setProducts(data))
    }, [])

    return (
        <div className='flex flex-col gap-6 py-10 bg-Shark text-white'>
            {createProductOpen && <NewProduct setCreateProductOpen={setCreateProductOpen} />}
            {editProductOpen && <EditProduct setEditProductOpen={setEditProductOpen} id={productToEditId} />}
            <h1 className='text-2xl font-semibold w-full text-center'>PRODUCTS</h1>
            <div className='flex flex-row justify-center items-center gap-4'>

                <button
                    type="button"
                    onClick={() => setCreateProductOpen(true)}
                    className="bg-WaterLeaf text-Shark h-8 font-bold rounded-full px-4 ml-4"
                >
                    Create New Product
                </button>
            </div>

            {products && products.map(product => (
                <div key={product.id} className='flex flex-row justify-center items-center gap-4 w-full'>
                    <div className='w-[150px]'>
                        <img src={product.imagen_url} className='w-10 h-10 rounded-full border-2' alt={product.name} />
                    </div>
                    <h1 className='text-lg w-[150px] text-center'>{product.brand.name}</h1>
                    <h1 className='text-lg w-[150px] text-center'>{product.model}</h1>
                    <h1 className='text-lg w-[150px] text-center'>$ {product.price.toLocaleString()}</h1>
                    <div className='text-lg w-[120px]'>{product.is_featured ?
                        <p className='bg-WaterLeaf text-black text-center rounded-full text-xs py-1'>FEATURED</p> : <p className='bg-black text-xs py-1 text-white text-center rounded-full'>NOT FEATURED</p>}</div>
                    <div className='text-lg w-[100px]'>{product.is_new ?
                        <p className='bg-WaterLeaf text-black text-center rounded-full text-xs py-1'>NEW</p> : <p className='bg-black text-xs py-1 text-white text-center rounded-full'>NOT NEW</p>}</div>
                    <button
                        type="button"
                        onClick={() => {
                            setProductToEditId(product.id)
                            setEditProductOpen(true)
                        }}
                        className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                    >
                        Editar
                    </button>
                    <button
                        type="button"
                        onClick={() => delProduct(product.id)}
                        className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                    >
                        Borrar
                    </button>
                </div>
            ))
            }

        </div>

    )
}

export default Products