import { create } from "zustand";
import { persist } from "zustand/middleware";

const useCart = create(
  persist(
    (set) => ({

      //USER
      user: null,
      setUser: (user) => set({ user }),
      closeSession: () => set({ user: null }),

      //SHOPPING CART
      open: false,
      setOpen: (open) => set({ open }),
      items: [],
      addToCart: (item) => set((state) => ({ items: [...state.items, item] })),
      removeFromCart: (item) =>
        set((state) => ({
          items: state.items.filter((i) => i.id !== item.id),
        })),
      clearCart: () => set((state) => ({ items: [] })),
      addItemQuantity: (item) =>
        set((state) => ({
          items: state.items.map((i) =>
            i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
          ),
        })),
        removeItemQuantity: (item) =>
        set((state) => {
          const updatedItems = state.items.map((i) =>
            i.id === item.id ? { ...i, quantity: i.quantity - 1 } : i
          );
          const itemToRemove = updatedItems.find((i) => i.id === item.id);
          if (itemToRemove.quantity >= 1) {
            return { items: updatedItems };
          } else {
            const filteredItems = updatedItems.filter((i) => i.id !== item.id);
            return { items: filteredItems };
          }
        }),

    }),

    {
      name: "cart-storage",
    }
  )
);

export default useCart;
