import React from "react";

function TopNavBar({ text, color, bgColor }) {
  return (
    <div
      className="flex items-center justify-center fixed z-50 w-full top-0 h-10"
      style={{ color: color, backgroundColor: bgColor }}
    >
      {text}
    </div>
  );
}

export default TopNavBar;
