import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from "react-icons/ai";


function Sorter({ list, setOrderOpen, table }) {


    const [lista, setLista] = useState(list);



    useEffect(() => {
        setLista(list)
    }
        , [list])


    async function sortBrands() {
        const response = await fetch('https://surroundback.vercel.app/sorter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lista: lista,
                tabla: table
            })
        })
        await response.json()
        window.location.reload();
    }


    const moveItemUp = (index) => {
        if (index === 0) return; // No se puede mover el primer elemento hacia arriba
        const newList = [...lista];
        const item = newList[index];
        newList[index] = newList[index - 1];
        newList[index - 1] = item;
        setLista(newList);
    };

    const moveItemDown = (index) => {
        if (index === lista.length - 1) return; // No se puede mover el último elemento hacia abajo
        const newList = [...lista];
        const item = newList[index];
        newList[index] = newList[index + 1];
        newList[index + 1] = item;
        setLista(newList);
    };

    return (
        <div className='fixed top-32 pt-32 w-full h-screen bg-Shark text-white flex flex-col gap-4'>
            <div className='absolute top-10 right-32'>
                <AiFillCloseCircle size={35} color="white" onClick={() => setOrderOpen(false)} className="cursor-pointer" />
            </div>
            {lista.map((item, index) => {
                return (
                    <div key={index} className='flex flex-row justify-center items-center gap-4'>
                        <button className='w-6 h-6 rounded-full ' onClick={() => moveItemUp(index)}>-</button>
                        <p>{item}</p>
                        <button className='w-6 h-6 rounded-full ' onClick={() => moveItemDown(index)}>+</button>
                    </div>
                )
            })}
            <div className='w-full flex items-center justify-center mt-20'>
                <button className='bg-white rounded-full px-6 py-2 text-black' onClick={sortBrands} >Enviar Orden</button>
            </div>
        </div>
    )
}

export default Sorter;
