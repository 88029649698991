import React, { useState, useEffect } from 'react'
import CloudinaryUploader from './CloudinaryUploader'
import Sorter from './Sorter'

function Brands() {
    const [brands, setBrands] = useState([])
    const [nuevaImagen, setNuevaImagen] = useState('')
    const [nuevaBrand, setNuevaBrand] = useState('')
    const [brandList, setBrandList] = useState([])
    const [orderOpen, setOrderOpen] = useState(false)


    async function addBrand() {
        const response = await fetch('https://surroundback.vercel.app/add-brand', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: nuevaBrand,
                imagen_url: nuevaImagen
            })
        })
        await response.json()
        window.location.reload();
    }



    async function deleteBrand(id) {

        const response = await fetch('https://surroundback.vercel.app/del-brand?id=' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },

        })
        await response.json()
        window.location.reload();
    }


    useEffect(() => {
        fetch('https://surroundback.vercel.app/brands')
            .then(response => response.json())
            .then(data => setBrands(data))
    }, [])

    useEffect(() => {
        if (brands) {
            setBrandList(brands.map(brand => brand.name))
        }
    }
        , [brands])


    return (
        <div className='flex flex-col gap-6 py-10 bg-Shark text-white'>
            <h1 className='text-2xl font-semibold w-full text-center'>BRANDS</h1>
            <div className='w-full flex items-center justify-center'>
                <button onClick={()=>setOrderOpen(true)} className='bg-black px-4 py-2 rounded-full'>Ordenar</button>
            </div>
            <div className='flex flex-row justify-center items-center gap-4'>
                {nuevaImagen ? <img src={nuevaImagen} className='w-10 h-10 rounded-full border-2' alt={nuevaBrand} /> :
                    <CloudinaryUploader setNuevaImagen={setNuevaImagen} />}
                <input
                    className="text-black text-center rounded-full px-4 py-2"
                    type="text"
                    name="Brand"
                    placeholder='New brand'
                    value={nuevaBrand}
                    onChange={(event) => setNuevaBrand(event.target.value)}
                />
                <button
                    type="button"
                    onClick={addBrand}
                    className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                >
                    Agregar
                </button>
            </div>
            {brands && brands.map(brand => (
                <div key={brand.id} className='flex flex-row justify-center items-center gap-4'>
                    <img src={brand.imagen_url} className='w-10 h-10 rounded-full border-2' alt={brand.name} />
                    <h1 className='text-lg'>{brand.name}</h1>
                    <button
                        type="button"
                        onClick={() => deleteBrand(brand.id)}
                        className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                    >
                        Borrar
                    </button>
                </div>
            ))
            }
            {orderOpen && <Sorter list={brandList} setOrderOpen={setOrderOpen} table="brands"/>}

        </div>
    )
}

export default Brands