import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import RichText from "./RichText";

function AboutUs({ auOpen }) {
  const [about, setAbout] = useState(null);

  useEffect(() => {
    const fetchAbout = async () => {
      const response = await fetch(
        `https://surroundback.vercel.app/about`
      );
      const data = await response.json();
      setAbout(data);
    };
    fetchAbout();
  }, []);

  return (
    auOpen && (
      <motion.div
        variants={{
          open: {
            opacity: 1,
          },
          closed: {
            opacity: 0,
          },
        }}
        initial="closed"
        animate={auOpen ? "open" : "closed"}
        className="bg-black bg-opacity-90 fixed top-20 z-40 w-full h-screen"
      >
        <motion.div
          variants={{
            open: {
              opacity: 1,
              y: "calc(30vh - 225px)",
              x: "calc(50vw - 225px)",
            },
            closed: {
              opacity: 0,
              x: "100vw",
              y: "calc(30vh - 225px)",
            },
          }}
          initial="closed"
          animate={auOpen ? "open" : "closed"}
          className="bg-white rounded-2xl h-[450px] w-[500px] shadow-2xl shadow-black flex flex-col px-20 justify-center items-center"
        >
          <h1 className="text-Shark text-2xl font-akzidenz-bold mb-4 text-center">
            ABOUT US
          </h1>
          <RichText text={about.text} align="center"/>
        </motion.div>
      </motion.div>
    )
  );
}

export default AboutUs;
