import React, { useState, useEffect } from 'react'
import EditFeatured from './EditFeatured'
import CreateFeatured from './CreateFeatured'

function FeaturedSection() {

    const [featured, setFeatured] = useState([])
    const [editFeaturedOpen, setEditFeaturedOpen] = useState(false)
    const [CreateFeaturedOpen, setCreateFeaturedOpen] = useState(false)
    const [slideToEdit, setSlideToEdit] = useState({})


    useEffect(() => {
        fetch('https://surroundback.vercel.app/featured')
            .then(response => response.json())
            .then(data => setFeatured(data))
    }, []
    )

    async function delSlide(id) {
        const response = await fetch('https://surroundback.vercel.app/del-featured-slide?id=' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        await response.json()
        window.location.reload();
    }

    return (
        <div className='h-screen w-full bg-Shark flex flex-col gap-6 pt-10'>
            <h1 className='text-2xl font-semibold w-full text-center text-white mb-10'>FEATURED SECTION</h1>
            <div className='w-full flex justify-center'>
              
                <button
                    onClick={() => setCreateFeaturedOpen(true)}
                    className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                >
                    Create New Slide   
                </button>
            </div>
            {featured.map((slide, index) => {

                return (
                    <div key={slide.id} className='flex flex-row gap-6 justify-center items-center'>
                        <img className='w-20 h-20 rounded-full object-cover' src={slide.imagen} alt="" />
                        <p className='text-white'>{slide.title}</p>
                        <button
                            type="button"
                            onClick={() => {
                                setSlideToEdit(slide)
                                setEditFeaturedOpen(true)
                            }}
                            className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                        >
                            Editar
                        </button>
                        <button
                            type="button"
                            onClick={() => delSlide(slide.id)}
                            className="bg-WaterLeaf text-Shark h-8 rounded-full px-4 ml-4"
                        >
                            Borrar
                        </button>
                    </div>
                )
            }
            )}

            {editFeaturedOpen && <EditFeatured setEditFeaturedOpen={setEditFeaturedOpen} slide={slideToEdit} />}
            {CreateFeaturedOpen && <CreateFeatured setCreateFeaturedOpen={setCreateFeaturedOpen} />}
        </div>
    )
}

export default FeaturedSection