import React, { useState } from 'react'
import CloudinaryUploader from './CloudinaryUploader'


function CreateHero({ setCreateHeroOpen }) {

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [titleColor, setTitleColor] = useState('')
    const [subtitleColor, setSubtitleColor] = useState('')
    const [textAlign, setTextAlign] = useState('')
    const [imagen, setImagen] = useState('')

    async function createSlide() {
        const response = await fetch('https://surroundback.vercel.app/add-hero-slide', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title,
                subtitle,
                title_color: titleColor,
                subtitle_color: subtitleColor,
                align: textAlign,
                imagen: imagen
            })
        })
        await response.json()
        window.location.reload();
    }

    console.log({ title, subtitle, titleColor, subtitleColor, textAlign, imagen})

    return (
        <div className='w-full h-screen fixed bg-black/80 top-0 flex justify-center items-start'>
            <div className='relative w-96 h-[650px] overflow-auto bg-white rounded-2xl flex flex-col items-center p-10 text-white mt-28'>
                <button onClick={() => setCreateHeroOpen(false)} className='absolute top-5 right-5 z-50 w-8 h-8 bg-Shark text-white rounded-full flex justify-center items-center'>x</button>
                <h1 className='text-black text-xl font-bold mt-6'>Create New Slide</h1>
                {imagen ? <img src={imagen} className=' h-[180px] border-2' alt="" /> :
                    <div className='w-full flex justify-center my-4 text-black'>
                        <CloudinaryUploader setNuevaImagen={setImagen} />
                    </div>}
                <input type="text" name="title" placeholder='Title' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setTitle(event.target.value)} />
                <div className='w-full flex flex-row justify-center items-center gap-4'>
                    <p className='text-black'>Title Color</p>
                    <input type="color" onChange={(event) => setTitleColor(event.target.value)} />
                </div>
                <input type="text" name="subtitle" placeholder='subtitle' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setSubtitle(event.target.value)} />
                <div className='w-full flex flex-row justify-center items-center gap-4'>
                    <p className='text-black'>Subtitle Color</p>
                    <input type="color" onChange={(event) => setSubtitleColor(event.target.value)} />
                </div>
                <select className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setTextAlign(event.target.value)}>
                    <option >Title Align</option>
                    <option value='flex-start'>Left</option>
                    <option value='flex-end'>Right</option>
                </select>
                <button onClick={createSlide} className='w-full h-10 mt-4 bg-Shark text-WaterLeaf py-6  rounded-full text-center flex justify-center items-center'>Create Slide</button>


            </div>
        </div>
    )
}

export default CreateHero