import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import RichText from "./RichText";

function Terms({ setTermsOpen }) {
  const [terms, setTerms] = useState("");

  useEffect(() => {
    fetch(
      "https://surroundprostrapi-production.up.railway.app/api/terms-and-condition"
    )
      .then((res) => res.json())
      .then((data) => {
        setTerms(data.data);
      });
  }, []);

  return (
    <div className="w-full h-screen bg-black bg-opacity-90 fixed top-20 z-50 flex flex-col pt-20 items-center overflow-scroll pb-40">
      <div
        className="w-[300px] md:w-[500px] lg:w-[800px] flex justify-between mb-4 cursor-pointer"
        onClick={() => setTermsOpen(false)}
      >
        <h1 className="font-akzidenz-bold text-2xl">Terms and Conditions</h1>
        <AiFillCloseCircle size={30} />
      </div>
      <div className="bg-white w-[80%] rounded-xl text-Shark text-center p-10 md:p-16 lg:p-20">
        {terms&&<RichText text={terms.attributes.Text}/>}
      </div>
    </div>
  );
}

export default Terms;
