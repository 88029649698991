import React, { useState } from 'react'
import CloudinaryUploader from './CloudinaryUploader'


function EditFeatured({ setEditFeaturedOpen, slide }) {

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [titleColor, setTitleColor] = useState('')
    const [subtitleColor, setSubtitleColor] = useState('')
    const [textAlign, setTextAlign] = useState('')
    const [nuevaImagen, setNuevaImagen] = useState('')
    const [imagen] = useState(slide.imagen)

    async function editSlide() {
        const response = await fetch('https://surroundback.vercel.app/update-featured-slide?id='+slide.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: slide.id,
                title,
                subtitle,
                title_color: titleColor,
                subtitle_color: subtitleColor,
                text_align: textAlign,
                imagen: nuevaImagen || imagen
            })
        })
        await response.json()
        window.location.reload();
    }

    return (
        <div className='w-full h-screen fixed bg-black/80 top-0 flex justify-center items-start'>
            <div className='relative w-96 h-[650px] overflow-auto bg-white rounded-2xl flex flex-col items-center p-10 text-white mt-28'>
                <button onClick={() => setEditFeaturedOpen(false)} className='absolute top-5 right-5 z-50 w-8 h-8 bg-Shark text-white rounded-full flex justify-center items-center'>x</button>
                <h1 className='text-black text-xl font-bold mt-6'>Edit Slide</h1>
                {!nuevaImagen && <img className='h-[180px]' src={imagen} alt="" />}
                {nuevaImagen ? <img src={nuevaImagen} className=' h-[180px] border-2' alt="" /> :
                    <div className='w-full flex justify-center my-4 text-black'>
                        <CloudinaryUploader setNuevaImagen={setNuevaImagen} />
                    </div>}
                <input type="text" defaultValue={slide.title} name="title" placeholder='Title' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setTitle(event.target.value)} />
                <div className='w-full flex flex-row justify-center items-center gap-4'>
                    <p className='text-black'>Title Color</p>
                    <input type="color" defaultValue={slide.title_color} onChange={(event) => setTitleColor(event.target.value)} />
                </div>
                <input type="text" defaultValue={slide.subtitle} name="subtitle" placeholder='subtitle' id="" className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setSubtitle(event.target.value)} />
                <div className='w-full flex flex-row justify-center items-center gap-4'>
                    <p className='text-black'>Subtitle Color</p>
                    <input type="color" defaultValue={slide.subtitle_color} onChange={(event) => setSubtitleColor(event.target.value)} />
                </div>
                <select defaultValue={slide.text_align} className='w-full h-10 mt-4 bg-Shark rounded-full text-center' onChange={(event) => setTextAlign(event.target.value)}>
                    <option value='flex-start'>Left</option>
                    <option value='flex-end'>Right</option>
                </select>
                <button onClick={editSlide}  className='w-full h-10 mt-4 bg-Shark text-WaterLeaf py-6  rounded-full text-center flex justify-center items-center'>Edit Slide</button>


            </div>
        </div>
    )
}

export default EditFeatured