import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Brands() {
  const navigate = useNavigate();
  const [products, setProducts] = useState();
  const { brand } = useParams();

 const urlend = brand === "Allen & Heath" ? "Allen%20%26%20Heath" : brand;
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch(
        `https://surroundback.vercel.app/products-per-brand?brand=${urlend}`
      );
      const products = await response.json();
      setProducts(products);
    };
    fetchProducts();
  }, [brand, urlend]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="flex flex-col bg-white w-full">
      <div className="flex flex-col bg-Shark mt-20 min-h-[700px]">
        <div className="flex flex-row justify-between px-10 md:px-40 py-20">
          <h1 className="font-akzidenz-bold text-white text-3xl">{brand}</h1>
          <AiFillCloseCircle
            size={35}
            color="white"
            onClick={() => navigate("/")}
            className="cursor-pointer"
          />
        </div>
        <div className="px-32 pb-20 flex flex-row gap-10 flex-wrap justify-center">
          {products
            ?.filter(
              (product) =>
                brand === "All" ||
                product.brand.name === brand
            )
            .map((product) => {

              
              return (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  Brand={product.brand.name}
                  Model={product.model}
                  Image={
                   product.imagen_url
                  }
                  Category={product.category.name}
                  Price={product.price}
                  InStock={product.in_stock}
                  Stripe_id={product.stripe_id}
                  IsPreOrder = {product.is_pre_order}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Brands;
