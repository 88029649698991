import React from 'react'

function CloudinaryUploader({setNuevaImagen}) {

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch('https://surroundback.vercel.app/upload-image', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                setNuevaImagen(data); // Suponiendo que la respuesta tiene una propiedad url con la url de la imagen
            })
            .catch(error => console.error('Error al subir la imagen:', error));
    };

    return (
        <div>
            <input
                type="file"
                name="imagen"
                id="imagen"
                onChange={(event) => {
                    const file = event.target.files[0];
                    uploadImage(file);
                }}
            />
        </div>
    )
}

export default CloudinaryUploader