import React, { useState } from 'react'

function CloudinaryUploaderMultiple({ setImagenes }) {
    const [imagenes, setLocalImagenes] = useState([]);
    console.log(imagenes);

    const uploadImage = (file) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch('https://surroundback.vercel.app/upload-image', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                setLocalImagenes(prevImagenes => [...prevImagenes, data]);
                setImagenes(prevImagenes => [...prevImagenes, data]);
            })
            .catch(error => console.error('Error al subir la imagen:', error));
    };

    return (
        <div>
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="multiple_files">Upload multiple files</label>
            <input
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="multiple_files"
                type="file"
                multiple
                onChange={(event) => {
                    const files = event.target.files;
                    for (let i = 0; i < files.length; i++) {
                        uploadImage(files[i]);
                    }
                }}
            />
        </div>
    )
}

export default CloudinaryUploaderMultiple