import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import RichText from "./RichText";

function Privacy({ setPrivacyOpen }) {
  const [privacy, setPrivacy] = useState(false);

  useEffect(() => {
    fetch(
      "https://surroundback.vercel.app/privacy"
    )
      .then((res) => res.json())
      .then((data) => {
        setPrivacy(data);
      });
  }, []);

  return (
    <div className="w-full h-screen bg-black bg-opacity-90 fixed top-20 z-50 flex flex-col pt-20 items-center overflow-scroll pb-40">
      <div
        className="w-[300px] md:w-[400px] lg:w-[800px] flex justify-between mb-4 cursor-pointer"
        onClick={() => setPrivacyOpen(false)}
      >
        <h1 className="font-akzidenz-bold text-2xl">Privacy Policy</h1>
        <AiFillCloseCircle size={30} />
      </div>
      <div className="bg-white w-[80%] rounded-xl text-Shark text-center p-10 md:p-16">
        {privacy&&<RichText text={privacy.text}/>}
      </div>
    </div>
  );
}

export default Privacy;
