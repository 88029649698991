import React from 'react';

const RichText = ({ text, align}) => {
  const textWithLineBreaks = text.replace(/\n/g, '<br />');
  const textWithBold = textWithLineBreaks.replace(/\*\*(.*?)\*\*/g, '<span class="font-akzidenz-bold">$1</span>');

  return (
    <div
      dangerouslySetInnerHTML={{ __html: textWithBold }}
      className={`text-${align}`}
    />
  );
};

export default RichText;
