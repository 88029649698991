import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Category() {
  const navigate = useNavigate();
  const [products, setProducts] = useState();
  const { category } = useParams();
  useEffect(() => {
    const fetchProducts = async () => {
      const url =
        category === "All"
          ? "https://surroundback.vercel.app/products"
          : `https://surroundback.vercel.app/products-per-category?cat=${category}`;
      const response = await fetch(url);
      const products = await response.json();
      setProducts(products);
    };
    fetchProducts();
  }, [category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col bg-white w-full">
      <div className="flex flex-col bg-Shark mt-20 min-h-[700px]">
        <div className="flex flex-row justify-between px-10 md:px-40 py-20">
          <h1 className="font-akzidenz-bold text-white text-3xl">{category}</h1>
          <AiFillCloseCircle
            size={35}
            color="white"
            onClick={() => navigate("/")}
            className="cursor-pointer"
          />
        </div>
        <div className="px-0 lg:px-32 pb-20 flex flex-row gap-10 flex-wrap justify-center">
          {products?.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              Brand={product.brand.name}
              Model={product.model}
              Image={
               product.imagen_url
              }
              Category={category}
              Price={product.price}
              Stripe_id={product.stripe_id}
              InStock={product.in_stock}
              IsPreOrder = {product.is_pre_order}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Category;
